import { PlatformLocation } from '@angular/common';
import { AfterContentChecked, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ConfirmationModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, Select, Store } from '@ngxs/store';
import { DataLayerService } from '@services/data-layer.service';
import { UserService } from '@services/user.service';
import { includes, isEmpty, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseComponent } from './baseComponent';
import { LayoutService } from './core/services/layout/layout.service';
import { WebsiteconfigService } from './core/services/websiteconfig.service';
import { InitAdBlockerChecker } from './core/store/actions/ad-blocker.actions';
import { AssignTokens, SetRainDeliveryTme } from './core/store/actions/auth.actions';
import { FetchDeliveryWaitTime, GetBillCycleOptions } from './core/store/actions/billing.actions';
import { InitDeviceChecker } from './core/store/actions/device-checker.actions';
import {
  FetchBlackFridayPromoConfig,
  FetchDefaultSkins,
  FetchGiveAGigPromoConfig,
  FetchLoadSheddingFallbackCopy,
  FetchNvidiaActivated,
  FetchPromos,
  FetchRainFlags,
  FetchRicaStatusCopy,
  FetchTopupConfig
} from './core/store/actions/firebase.actions';
import { AssignSlug } from './core/store/actions/routing.actions';
import { CoreState } from './core/store/state/core.state';
import { CACHE_AUTH_TOKEN, CACHE_CART, CACHE_IDM_TOKEN } from './models/constants';
import { AuthenticationService } from './services/auth.service';
import { CacheService } from './services/cache.service';
import { CartService } from './services/cart.service';
import { ElementService } from './services/element.service';
import { CartExpiryService, IdleService } from './services/idle.service';
import { TokenService } from './services/token.service';
import { GetAllOrders } from './store/actions/order.actions';
import { FetchProductsFromCatelogue, GetAllProducts, SetBlackFridayProducts } from './store/actions/product.actions';
import { BlackFridayProductModel, ProductState } from './store/state/product.state';
import { register } from 'swiper/element/bundle';
import { GetBlackFridayVoucher } from './core/store/actions/voucher.actions';
import { Meta, Title } from '@angular/platform-browser';
//TODO : jquery needs to be removed
declare var $: any;
const redirectToHome = ['/login', '/activate-SIM', '/activate-SIM/select-gotSIM'];
register();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Select(ProductState.CatelogueIsLoaded) catelogueIsLoaded$: Observable<boolean>;
  previousUrl: string;
  blackFridayProductsmap: { [key: string]: BlackFridayProductModel } = {
    'f33cc102-cacb-491f-84b7-080fd3c7bd0b': {
      bf: '5g_upfront_premium_black_friday',
      original: 'standard_5g_upfront_v1',
      boostedFrom: 'unlimited home 5G standard',
      boostedTo: 'unlimited home 5G premium',
      originalPrice: 999
    },
    '6c7e7644-9a6d-4e8e-a541-520dbf7f0512': {
      bf: '5g_postpaid_premium_black_friday',
      original: '9',
      boostedFrom: 'unlimited home 5G standard',
      boostedTo: 'unlimited home 5G premium',
      originalPrice: 999
    },
    'cca0fe79-34d4-462b-aacd-20fc7da8cd85': {
      bf: '5g_upfront_standard_black_friday',
      original: 'basic_5g_upfront_v1',
      boostedFrom: 'unlimited home 5G basic',
      boostedTo: 'unlimited home 5G standard',
      originalPrice: 739
    },
    'b236d2f9-f4cb-4efc-847b-b780f9dcf2f3': {
      bf: '5g_postpaid_standard_black_friday',
      original: '11',
      boostedFrom: 'unlimited home 5G basic',
      boostedTo: 'unlimited home 5G standard',
      originalPrice: 739
    }
  };
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private idleTimeoutService: IdleService,
    private cartTimeoutService: CartExpiryService,
    private authService: AuthenticationService,
    private cartService: CartService,
    private elementService: ElementService,
    private cacheService: CacheService,
    private modalService: NgbModal,
    private platformLocation: PlatformLocation,
    private tokenService: TokenService,
    public websiteConfig: WebsiteconfigService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private store: Store,
    private layoutService: LayoutService,
    private titleService: Title,
    private meta: Meta
  ) {
    super();
    console.log(`--- version: ${environment.version} ---`);
    window.addEventListener('scroll', e => {
      const scrollPos = document.body.getBoundingClientRect().top;
      const nav = document.getElementById('header-menu');
      const currentRouter = this.router.url;

      if (currentRouter === '/' || currentRouter === '/home') {
        if (scrollPos === 0) {
          return nav.classList.remove('mobile-header');
        }
        if (scrollPos < 100) {
          nav.classList.add('mobile-header');
        } else {
          nav.classList.remove('mobile-header');
        }
      } else {
        if (scrollPos < 80) {
          nav.classList.add('mobile-header');
        } else {
          nav.classList.remove('mobile-header');
        }
      }
    });

    this.platformLocation.onPopState(() => this.modalService.dismissAll());

    this.cartTimeoutService.onExpired.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.cartService.clear();
    });

    this.tokenService.onExpired.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.signOut();
    });

    this.idleTimeoutService.onExpired.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.signOut();
    });

    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pageViewEvent();
        this.getSlug(event.url);
        if (authService.isSignedIn) {
          this.idleTimeoutService.resetTimer();
        }

        window.scrollTo(0, 0);
      }
      if (event instanceof NavigationStart) {
        if (isNil(this.previousUrl) == false) {
          this.renderer.removeClass(document.body, this.previousUrl);
        } else {
          if (includes(redirectToHome, event.url)) {
            this.previousUrl = 'none';
            this.router.navigate(['/']);
            return;
          }
          this.modalService.dismissAll();
        }

        const currentUrlSlug = event.url.slice(1);
        const revisedSlug =
          isEmpty(currentUrlSlug) ||
          currentUrlSlug.includes('?') ||
          currentUrlSlug === '' ||
          currentUrlSlug === 'more-about-4g' ||
          currentUrlSlug === 'e-sim' ||
          currentUrlSlug === 'fiveg' ||
          currentUrlSlug === 'activate-SIM' ||
          currentUrlSlug === 'find-SIM' ||
          currentUrlSlug === 'support' ||
          currentUrlSlug === 'rain-maker' ||
          currentUrlSlug === 'select-gotSIM' ||
          currentUrlSlug === 'about-my-rain' ||
          currentUrlSlug.includes('register')
            ? 'onscroll'
            : 'none';

        this.renderer.addClass(document.body, revisedSlug);

        this.renderer.removeClass(document.body, 'white');

        this.renderer.removeClass(document.body, 'support');

        if (currentUrlSlug !== '') {
          this.renderer.removeClass(document.body, 'home');
        }

        this.previousUrl = revisedSlug;
      }
    });

    this.store.dispatch([
      new FetchProductsFromCatelogue(),
      new SetBlackFridayProducts(this.blackFridayProductsmap),
      new FetchLoadSheddingFallbackCopy(),
      new FetchRainFlags(),
      new FetchGiveAGigPromoConfig(),
      new FetchTopupConfig(),
      new FetchBlackFridayPromoConfig(),
      new FetchNvidiaActivated(),
      new InitDeviceChecker(),
      new InitAdBlockerChecker(),
      new GetBillCycleOptions(),
      new FetchDeliveryWaitTime(),
      new GetAllProducts(),
      new FetchRicaStatusCopy(),
      new FetchPromos(),
      new FetchDefaultSkins()
    ]);

    this.layoutService.subscribeToLayoutChanges()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe();
  }

  private signOut() {
    this.authService.signOut();

    $('.modal').modal('hide');

    this.elementService.scrollToHome();
  }

  ngOnInit() {

    //update page title and description
    const pageTitle = 'rain | 4G & 5G network';
    this.titleService.setTitle(pageTitle);
    this.meta.updateTag({ property: 'og:title', content: pageTitle });
    const metaDescription =
      'Keep your work & home connected with unlimited 5G wifi +FREE calls & data for your phones. Change to rainOne from R625pm. No contracts. Ts & Cs apply.';
    this.meta.updateTag({ name: 'description', content: metaDescription });
    this.meta.updateTag({ property: 'og:description', content: metaDescription });

    this.getSiteConfig();
    this.listenToGlobalSignout();
    this.getGlobalNotifications();
    this.getDeliveryWaitTime();
    if (this.authService.isSignedIn === false) {
      this.cacheService.clear([CACHE_CART]);
    }
    this.pageViewEvent();

    if (this.authService.isSignedIn) {
      this.store.dispatch(new GetAllOrders());
    }
  }

  public ngAfterContentChecked(): void {
    this.onAppRefresh();
  }

  pageViewEvent() {
    // Track page view for analytics -- starts
    const pageTitles = {
      // Static routes mapped to page titles
      '/': 'Home',
      '/activate-SIM': 'Activate SIM',
      '/register': 'create an account or sign in',
      '/register/activate': 'Register - Activate',
      '/about-my-rain': 'About My Rain',
      '/more-about-4g': '4g: The sim that saves',
      '/fiveg': '5g: fast internet delivered fast',
      '/login': 'Login',
      '/careers': 'Careers: we believe in diversity',
      '/legal': 'Legal',
      '/my-rain': 'My rain',
      '/my-account': 'My Account',
      '/change-password': 'My Account - Change Password',
      '/payment-details': 'Payment Details',
      '/mobile-terms-and-conditions': 'Mobile Terms and Conditions',
      '/rain-maker': 'Rain Maker',
      '/rain-maker/register': 'Rain Maker: Register',
      '/rain-maker/register/success': 'Rain Maker: Register: Success',
      '/arrears-payment-status': 'Payment Status: Arrears',
      '/payment-status': 'Payment Status',
      '/pay-now-payment-status': 'Payment Status: Pay Now',
      '/cart': 'My Cart',
      '/coverage': 'Check Coverage',
      '/promo-terms': 'Promotional T&C-black Friday promo',
      '/notifications': 'Notification-preference',
      '/purchase-wizard': 'Purchase Wizard',
      '/skin-selector': 'Purchase Journey - skin selection',
      '/speed-up': 'Purchase Journey - wifi speed selection',
      '/manage-geforce': 'Manage geforce',
      '/4Gsetup': '4G mobile set up',
      '/4Gmobile': '4G mobile',
      '/our-network': 'Our Network',
      '/tutorials': 'Tutorials',
      '/mnp/port-out/validate': 'OTP verification',
      '/port-in': 'Port my number',
      '/level-up': 'Purchase Journey - mobile level selection',
      '/nvidia': 'Nvidia - landing',
      '/nvidia/games-list': 'Nvidia - games list',
      '/nvidia/download': 'Nvidia - download',
      '/nvidia/faq': 'Nvidia - FAQ',
    };

    // Handling for dynamic routes
    const urlFragments = this.router.routerState.snapshot.url.replace('/', '').split('/');

    switch (urlFragments[0]) {
      case 'service':
        const serviceId = urlFragments[1];
        if (serviceId) {
          this.dataLayerService.pageView(
            this.router.routerState.snapshot.url,
            `Service - ${serviceId}`,
            !this.authService.isSignedIn ? null : this.userService.getUserId()
          );
        }
        break;

      default:
        if (pageTitles[this.router.routerState.snapshot.url]) {
          this.dataLayerService.pageView(
            this.router.routerState.snapshot.url,
            pageTitles[this.router.routerState.snapshot.url],
            !this.authService.isSignedIn ? null : this.userService.getUserId()
          );
        }
        break;
    }
    // Track page view for analytics -- ends
  }

  getSiteConfig() {
    this.websiteConfig.setGlobalConfigs().then(
      res => {
        if (res) {
        }
      },
      error => {
        console.log('ERROR with site config subscription ' + error);
      }
    );
  }

  getDeliveryWaitTime() {
    this.websiteConfig
      .getDeliveryDelay()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((deliveryTime: any) => {
        if (deliveryTime) {
          this.store.dispatch(new SetRainDeliveryTme(deliveryTime?.days));
        }
      });
  }

  listenToGlobalSignout() {
    this.websiteConfig.listenToGlobalSignout()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (res: any) => {
        if (res?.signout) {
          this.authService.signOut();
          this.router.navigateByUrl('/');
        }
      },
      error => {
        console.log('ERROR with global signout subscription ' + error);
      }
    );
  }

  getGlobalNotifications() {
    this.websiteConfig.getGlobalNotifications()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (res: any) => {
        if (localStorage.getItem('showedGlobalMessage') !== 'true' && res && res.show) {
          this.showAlert(res);
        }

        if (localStorage.getItem('showedGlobalMessage') === 'true' && res && !res.show) {
          localStorage.removeItem('showedGlobalMessage');
        }
      },
      error => {
        console.log('ERROR with global notifications subscription ' + error);
      }
    );
  }

  showAlert(res) {
    const data = {
      title: res.message.title,
      description: res.message.description,
      buttonName: res.button
    };
    const alertMsg = this.modalService.open(ConfirmationModalComponent, {
      size: <any>'confirm',
      windowClass: 'slideInUp',
      centered: true
    });
    alertMsg.componentInstance.data = data;
    localStorage.setItem('showedGlobalMessage', 'true');
  }

  private onAppRefresh() {
    const isAuthed = this.store.selectSnapshot(CoreState.isAuthenticated);
    const hasTokens = this.store.selectSnapshot(CoreState.hasTokensLoaded);

    if (isAuthed && !hasTokens) {
      const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
      const authToken = this.tokenService.get(CACHE_AUTH_TOKEN);

      if (idmToken && authToken) return this.store.dispatch(new AssignTokens({ idm: idmToken, auth: authToken }));
    }
  }

  private getSlug(url: string) {
    return this.store.dispatch(new AssignSlug(url));
  }

}
