import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { SwitchUIModeService } from '@services/switch-uimode.service';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { UIMode, UIState } from 'src/app/shared/store/state/ui.state';


@Component({
  selector: 'rain-product-nav',
  templateUrl: './product-nav.component.html',
  styleUrls: ['./product-nav.component.scss']
})
export class ProductNavComponent extends BaseComponent implements OnInit {
  @Select(UIState.GetUIMode) selectedMode$: Observable<UIMode>;
  selectedProduct: string;
  uiMode: string;

  constructor(
    private store: Store,
    private switchUIModeService: SwitchUIModeService) {
    super();
  }

  ngOnInit(): void {
    this.selectedMode$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      this.uiMode = result;
    });
    const ProductMode = this.store.selectSnapshot(UIState.GetUIMode);
    //this.switchUIMode(ProductMode);
    this.selectedProduct = ProductMode;
  }

  selectProduct(NavImage: string): void {
    this.selectedProduct = NavImage;
    this.switchUIMode(this.selectedProduct);
  }
  
  switchUIMode(mode: string): void {
    this.switchUIModeService.switchUIMode(mode, this.uiMode);
  }
}
