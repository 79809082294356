<div class="purchase-tile-wrapper" *ngIf="(activeBreakpoint$ | async ) as deviceType">
    <div class="top-position-wrapper" *ngIf="position === 1">

        <div class="rain-one-101-tile-container consumer-wrapper-1" *ngIf=" (selectedMode$ |async) === 'consumer'">
            <div class="rain-one-101-tile-wrapper">
                <div class="product-lockup-wrapper">
                    
                    <img *ngIf="deviceType === 'mobile'" class="rainOne-img"
                        src="/assets/images/rain-101/homepage/rainone_home.svg?v=1" alt="">
                    <P *ngIf="deviceType === 'mobile'" class="mobile-one-plan-text">one plan for your <br> <span class="bold-text ">home & phones</span></P>
                    <img class="rainOne-product-img" src="/assets/images/rain-101/homepage/rainOne-blueberry-cpe.webp" alt="">
                </div>
                <div class="product-description-wrapper">
                    <img *ngIf="deviceType !== 'mobile'" class="rainOne-img"
                        src="/assets/images/rain-101/homepage/rainone_home.svg?v=1" alt="">
                    <P *ngIf="deviceType !== 'mobile'" class="one-plan-text">one plan for your <br> <span class="bold-text ">home & phones</span></P>
                    <p class="unlimited-5G-top-text"><span class="bold-text">unlimited</span> 5G home wifi
                        <br>
                        +<span class="bold-text">FREE </span> monthly calls & data for 2 phones<br>& <span><span
                                class="bold-text">  the101</span>™ 5G smart router free-to-use.</span>
                    </p>
                    <div class="terms"><a (click)="gotoTerms();">Ts & Cs apply.</a></div>
                </div>

            </div>
        </div>

    </div>


    <div class="bottom-position-wrapper" *ngIf="position ===2">
        <div class="rain-one-101-tile-container" *ngIf="(selectedMode$ |async) === 'sme'">
            <div class="rain-one-101-tile-wrapper">
                <div class="product-lockup-wrapper">
                    <img *ngIf="deviceType === 'mobile'" class="mobile-rainOne-work-logo"
                        src="/assets/images/rain-101/homepage/rainOne-work-logo.svg?v=1" alt="">
                    <br>
                    <img class="rainOne-product-img" src="/assets/images/rain-101/rainOne-work-silver.webp" alt="">

                </div>
                <div class="product-description-wrapper">
                    <img *ngIf="deviceType !== 'mobile'" class="rainOne-work-logo"
                        src="/assets/images/rain-101/homepage/rainOne-work-logo.svg?v=1" alt="">
                    <p class="unlimited-5G-bottom-text"><span class="bold-text">unlimited</span> 5G work wifi
                        <br>
                        +<span class="bold-text">FREE </span> monthly calls & data for 5 phones
                    </p>
                    <ul>
                        <li> <span  class="bold-text">Unlimited</span> 5G work wifi</li>
                        <li> <span class="bold-text">the101</span>™ 5G smart router free-to-use</li>
                        <li> <span class="bold-text">5 FREE</span> 4G mobile SIMs</li>
                        <li> Each SIM comes with:
                            <br>
                            2GB | 60min | 100sms <span class="bold-text">FREE every month</span>
                        </li>
                        <li>Works for your <span class="bold-text">small business</span></li>
                    </ul>
                    <div class="rain-one-button-container">
                        <div class="price-wrapper">
                            <span class="price">R795</span>
                            <span class="month-to-month">month-to-month</span>
                        </div>{{topPosition}}
                    </div>
                </div>

            </div>

        </div>

        <div class="rain-one-101-tile-container" *ngIf=" (selectedMode$ |async) === 'consumer'">
            <div class="rain-one-101-tile-wrapper">
                <div class="product-lockup-wrapper">
                    <img *ngIf="deviceType === 'mobile'" class="rainOne-img mb-10"
                        src="/assets/images/rain-101/homepage/rainone_home.svg?v=1" alt="">
                    <img class="rainOne-product-img " src="/assets/images/rain-101/homepage/rainOne-home-stone.webp" alt="">


                </div>
                <div class="product-description-wrapper">
                    <img *ngIf="deviceType !== 'mobile'" class="rainOne-img"
                        src="/assets/images/rain-101/homepage/rainone_home.svg?v=1" alt="">
                    <p class="unlimited-5G-bottom-text"><span class="bold-text">unlimited</span> 5G home wifi
                        <br>
                        +<span class="bold-text">FREE </span> monthly calls & data for 2 phones
                    </p>

                    <ul>
                        <li><span class="bold-text">Unlimited</span> 5G home wifi</li>
                        <li> <span class="bold-text">the101</span>™ 5G smart router free-to-use</li>
                        <li> <span class="bold-text">2 FREE</span> 4G mobile SIMs</li>
                        <li> Each SIM comes with:
                            <br>
                            2GB | 60min | 100sms <span class="bold-text">FREE every month</span>
                        </li>
                    </ul>

                    <div class="rain-one-button-container">
                        <div class="price-wrapper">
                            <span class="price">R625</span>
                            <span class="month-to-month">month-to-month</span>
                        </div>{{topPosition}}
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>