import {Component, OnInit} from '@angular/core';
import {ElementService} from '@services/element.service';
import {DEFAULT_LATITUDE, DEFAULT_LONGITUDE, RAIN_ONE_LEVELS} from '@models/constants';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../baseComponent';
import {CoverageMapModes} from '@services/geo.service';
import {ConfigStoreService} from 'src/app/store/config.store.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {VoucherActivateModalComponent} from '@components/voucher-activate-modal/voucher-activate-modal.component';
import {CustomerRatingModalComponent} from '@components/customer-rating-modal/customer-rating-modal.component';
import {Select, Store} from '@ngxs/store';
import {CoreState} from 'src/app/core/store/state/core.state';
import {Observable} from 'rxjs';
import {ProductState} from 'src/app/store/state/product.state';
import {ImageViewModel} from '../../shared/selectors/image-view-model';
import {Rain101Templates} from 'src/app/core/enums/rain-101-templates.enum';
import {Navigate} from '@ngxs/router-plugin';
import {SalesModalComponent} from '@components/sales-modal/sales-modal.component';
import {
    RainOneModalVideoComponent
} from 'src/app/shared/components/rain-101/rain-one-modal-video/rain-one-modal-video.component';
import {FirebaseConfigsState} from 'src/app/core/store/state/firebase-configs.state';
import {RainFlags} from 'src/app/core/interfaces/rain-flags.interface';
import {UIMode, UIState} from 'src/app/shared/store/state/ui.state';
import {UIActions} from 'src/app/shared/store/actions/ui.actions';
import {DataLayerService} from '@services/data-layer.service';
import {SSOService} from 'src/app/core/services/sso.service';
import {
    SalesUpgradeModalComponent
} from '@components/sales-modal/sales-upgrade-modal/sales-upgrade-modal/sales-upgrade-modal.component';
import {Base64UrlService} from '@services/base64-url.service';

//TODO we must remove jquery
declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {
    @Select(FirebaseConfigsState.getFlags) flags$: Observable<RainFlags>;
    @Select(UIState.GetUIMode) selectedMode$: Observable<UIMode>;
    uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    showThisSection: boolean = false;
    decodedName: string = '';
    decodedLastName: string = '';
    decodedEmail: string = '';

    items = [
        {
            id: '1',
            title: 'connect with colour',
            subText: '<span class="font-avant"><b>the101<sup>&trade;</sup></b></span> 5G smart router is here. <br>Only on <b>rainone</b>',
            fontColor: '#fff',
            backgroundColor: '#b3b1a8',
            image: `assets/images/rain-101/homepage/wheel-2.png`,
            mobileImage: `assets/images/rain-101/homepage/wheel-2.png`,
            type: Rain101Templates.Promo
        },
        {
            id: '2',
            title: '<span class="font-avant">the101<sup>&trade;</sup></span>',
            backgroundColor: '#8aa299',
            fontColor: '#fff',
            subText: 'with <b>touch<br> screen</b> display',
            mobileImage: 'assets/images/rain-101/homepage/CPE_101_Screen_HOME.png',
            titleStyle: 'aligned-right',
            image: 'assets/images/rain-101/homepage/CPE_101_Screen_HOME.png',
            type: Rain101Templates.Left_Aligned_Image
            // hasTextOverImage: true,
            // overImageText: '<span class="font-avant">the 101</span>',
        },
        {
            id: '3',
            title: '<span class="font-avant"><b>the101<sup>&trade;</sup></b></span>',
            subText: 'with powerful<br><b>AX3600 wifi</b>',
            fontColor: '#fff',
            backgroundColor: '#2e2e2e',
            image: `assets/images/rain-101/homepage/Tech_Image_wide.png`,
            mobileImage: 'assets/images/rain-101/homepage/Tech_Image_wide.png',
            type: Rain101Templates.Powerful_wifi
        },
        {
            id: '4',
            title: '<span class="font-avant"><b>the101<sup>&trade;</sup></b></span>',
            subText: ' with <b>scan<br class="hide-mobile"> to connect</b><div class="pb-5"></div>',
            fontColor: '#626E7A',
            backgroundColor: '#ECF1F4',
            image: `assets/images/rain-101/homepage/grid-images/red-skin-image.png`,
            type: Rain101Templates.Centered_Image_Left_Aligned_Text,
            name: 'item-4'
        },
        {
            id: '5',
            title: '<span class="font-avant"><b>the101<sup>&trade;</sup></b></span>',
            subText: 'with <b>direct<br>messaging</b>',
            fontColor: '#626E7A',
            backgroundColor: '#ECF1F4',
            image: `assets/images/rain-101/homepage/grid-images/blue-skin-image.png`,
            type: Rain101Templates.Centered_Image_Left_Aligned_Text,
            name: 'item-5'
        },
        {
            id: '6',
            title: '<span class="font-avant"><b>the101<sup>&trade;</sup></b></span>',
            subText: 'with built-in <b><br>speed test</b>',
            fontColor: '#626E7A',
            backgroundColor: '#F4F7F9',
            image: `assets/images/rain-101/homepage/grid-images/orange-skin-image.png`,
            type: Rain101Templates.Centered_Image_Left_Aligned_Text
        }
    ];

    itemsForPhones = [
        {
            id: '1',
            title: ' ',
            subText: '<h2>FREE mobile</h2><p>for 2 phones</p><p>Only on <img>',
            fontColor: '#0077C8',
            backgroundColor: '#E7ECEF',
            image: `assets/images/rain-101/homepage/SIIMbox4.png`,
            mobileImage: 'assets/images/rain-101/homepage/SIIMbox4.png',
            displayOn: ['mobile', 'ipad', 'ipadPro', 'desktop'],
            type: Rain101Templates.Grid_column_left_copy_overlapping_image,
            name: 'for-your-phones-1'
        },
        {
            id: '2',
            title: ' ',
            subText:
                '<p><span class="font-weight-bold pb-15 d-block">both get</span><span class="d-block "><span class="font-weight-bold">2</span> gigs</span><span class="d-block"><span class="font-weight-bold">60</span> minutes</span><span class="d-block"><span class="font-weight-bold">100</span> sms</span><span class="d-block pt-15"><span class="font-weight-bold">FREE</span><br> every month</span> </p>',
            fontColor: '#0077C8',
            backgroundColor: '#ECF1F4',
            image: `assets/images/rain-101/homepage/girlinlime1.png`,
            mobileImage: 'assets/images/rain-101/homepage/girlinlime1.png',
            displayOn: ['mobile'],
            type: Rain101Templates.Grid_row_with_image_bottom,
            name: ''
        },
        {
            id: '3',
            title: ' ',
            subText:
                '<p class="hd-video"><span class="d-block">With <span class="font-weight-bold">HD voice calls</span></span><span class="d-block"> & national 4G</span><span class="d-block">coverage.</span></p><p class="hd-video d-block mt-lg-18 mt-md-5  mt-sm-5 mt-xs-5 h3-dynamic"> Plus top up on the go with <span class="font-weight-bold"><a href="https://www.raingo.co.za/" target="_blank">rainGO.</a></span></p>',
            fontColor: '#0077C8',
            backgroundColor: '#ECF1F4',
            image: `assets/images/rain-101/homepage/rainGO_device.png`,
            mobileImage: 'assets/images/rain-101/homepage/rainGO_device.png',
            displayOn: ['mobile', 'desktop'],
            type: Rain101Templates.Grid_row_with_image_bottom,
            name: 'item-3',
            navigation: 'go to rainGo'
        },
        {
            id: '4',
            title: ' ',
            subText:
                '<span class="h2-dynamic font-weight-bold d-block">level <sup>up</sup></span><span class="d-block pt-3 h3-dynamic">Get more data & minutes<br> every month for <strong>R75</strong> a level.</span>',
            fontColor: '#0077C8',
            backgroundColor: '#ECF1F4',
            image: `assets/images/rain-101/homepage/levelup-chart.png`,
            mobileImage: 'assets/images/rain-101/homepage/levelup-chart.png',
            displayOn: ['mobile', 'desktop', 'ipad', 'ipadPro'],
            type: Rain101Templates.Level_Up,
            name: 'grid-2-span-2'
        }
    ];

    GeForceItems = [
        {
            id: '1',
            title: 'GeForce NOW <br> <strong>Powered by </strong>',
            subText: 'Game anywhere, on any device.',
            fontColor: '#fff',
            backgroundColor: '#F8F9FA',
            image: `assets/images/nvidia/desktop-logo.svg`,
            mobileImage: 'assets/images/nvidia/desktop-logo.svg',
            type: Rain101Templates.GeForce
        },
        {
            id: '2',
            title: '<span>GeForce Now</span> <br> is here',
            subText: '',
            fontColor: '#ffffff',
            backgroundColor: '#0B090A',
            image: `assets/images/nvidia/geForceNowSide.png`,
            mobileImage: 'assets/images/rain-101/homepage/Tech_Image_wide.png',
            type: Rain101Templates.GeForceNowHere
        }
    ];

    skinColors: string[] = ['Light', 'PINK', 'Dark'];
    selectedSkinColor: string = '';

    @Select(ProductState.CatelogueIsLoaded) catalogueIsLoaded$: Observable<boolean>;
    @Select(CoreState.activeBreakpoint) ap$: Observable<string>;
    @Select(ImageViewModel.getRainOneBundleBG) image$: Observable<string>;

    public coverageMapModes = CoverageMapModes;
    public scrollToShop: () => void;
    public latitude: number = DEFAULT_LATITUDE;
    public longitude: number = DEFAULT_LONGITUDE;
    messages: string[];
    hasAddress: boolean;

    public levels = RAIN_ONE_LEVELS;

    public wifiLevels = [
        {
            level: '30',
            id: '1',
            speed: '30',
            cost: '',
            selected: false,
            includedInSelection: false,
            isSelectable: false
        },
        {
            level: '60',
            id: '2',
            speed: '60',
            cost: '200',
            selected: false,
            includedInSelection: false,
            isSelectable: false
        },
        {
            level: '100+',
            id: '3',
            speed: '100+',
            cost: '400',
            selected: false,
            includedInSelection: false,
            isSelectable: false
        }
    ];

    imgs = new Array();

    constructor(
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private elementService: ElementService,
        public configStoreService: ConfigStoreService,
        private router: Router,
        private store: Store,
        private dataLayerService: DataLayerService,
        private sso: SSOService,
        private base64UrlService: Base64UrlService
    ) {
        super();
        this.scrollToShop = this.elementService.scrollToShop;
    }

    private setCurrentPosition() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
            });
        }
    }

    selectSkinColor(color: string): void {
        this.selectedSkinColor = color;
    }

    public sliderOn: boolean = false;

    ngOnInit() {
        this.preload('assets/images/rain-101/rain-101-router-shadow.png');

        if (this.router.url.includes('work')) {
            this.store.dispatch(new UIActions.SwitchUIMode({mode: 'sme'}));
        }

        // this.setCurrentPosition();
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            if (params.hasOwnProperty('voucherNumber') && params.hasOwnProperty('emailAddress')) {
                const voucherModal = this.modalService.open(VoucherActivateModalComponent, {
                    centered: true,
                    size: <any>'sm',
                    windowClass: 'slideInUp d-flex'
                });
                const details = {
                    voucherNumber: this.sanitizeCode(params.voucherNumber),
                    emailAddress: params.emailAddress
                };
                voucherModal.componentInstance.voucherDetails = details;
                return voucherModal;
            }

            if (params.hasOwnProperty('rating') && params.hasOwnProperty('campaignId') && params.hasOwnProperty('email')) {
                this.showCustomerRatingModal(params.email, params.rating, params.campaignId);
                return;
            }

            if (params.hasOwnProperty('freeUpgrade')) {
                const {n, l, e} = params;

                if (n && l && e) {
                    try {
                        const name = this.base64UrlService.decodeBase64Url(n);
                        const lastName = this.base64UrlService.decodeBase64Url(l);
                        const email = this.base64UrlService.decodeBase64Url(e);

                        this.upgradeSaleModal(name, lastName, email);
                    } catch (error) {
                        console.error('Error decoding Base64 URL:', error);
                    } finally {
                        setTimeout(() => {
                            this.removeQueryParams();
                        }, 0);

                    }
                }
            }


            const mode = this.store.selectSnapshot(UIState.GetUIMode);
            switch (mode) {
                case 'sme':
                    this.store.dispatch(new UIActions.SwitchUIMode({mode: 'sme'}));
                    break;
                case 'consumer':
                    this.store.dispatch(new UIActions.SwitchUIMode({mode: 'consumer'}));
                    break;
                case 'mobile':
                    this.store.dispatch(new UIActions.SwitchUIMode({mode: 'mobile'}));
                default:
                    this.store.dispatch(new UIActions.SwitchUIMode({mode: 'consumer'}));
                    break;
            }

            if (!window.location.href?.toLocaleLowerCase()?.includes('/work')) {
                this.store.dispatch(new UIActions.SwitchUIMode({mode: 'consumer'}));
            }
        });
        // this.store.dispatch(new GetBlackFridayVoucher)
    }

    preload(...args: string[]): void {
        for (var i = 0; i < args.length; i++) {
            this.imgs[i] = new Image();
            this.imgs[i].src = args?.[i];
        }
    }

    showCustomerRatingModal(email: string, rating: string, campaignId: string) {
        const modalRef = this.modalService.open(CustomerRatingModalComponent, {
            centered: true,
            size: <any>'md'
        });
        modalRef.componentInstance.email = email;
        modalRef.componentInstance.rating = rating;
        modalRef.componentInstance.campaignId = campaignId;
    }

    private sanitizeCode(code: string) {
        if (!code.includes("'")) return code;
        return code.replace("'", '');
    }

    public getSupportBtnText() {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 410) {
            return 'call me';
        }
        return 'request a call';
    }

    public goToTerms() {
        this.router.navigateByUrl('/legal?section=terms');
    }

    public gotoTermsRainGo() {
        this.router.navigateByUrl('/legal?section=rainGo');
    }

    public goToCompetitionTerms() {
        this.router.navigateByUrl('/legal?section=competition');
    }

    public displayResponsive(breakPoint, breakPoints) {
        if (breakPoints && breakPoint) {
            return Boolean(breakPoints.includes(breakPoint));
        }
    }

    handleRequestSalesCallFromSticky() {
        const options = {size: 'lg' as any, windowClass: 'slideInUp', centered: true};
        const salesModalRef = this.modalService.open(SalesModalComponent, options);
        const productView = this.uiMode === 'sme' ? 'rainOne Work' : 'rainOne Home';

        salesModalRef.componentInstance.productName = productView;
        salesModalRef.componentInstance.subject = 'request a call';
        salesModalRef.componentInstance.isPromo = false;
        salesModalRef.componentInstance.showSignIn = false;
        salesModalRef.componentInstance.gmData = `From sticky - submit form`;
        salesModalRef.componentInstance.fromPage = 'homepage';
    }

    handlePlayVideoTrigger() {
        // play video
        const options = {size: 'xl' as any, windowClass: 'videoModal', centered: true};
        const modalRef = this.modalService.open(RainOneModalVideoComponent, options);
    }

    public goToWizard() {
        this.store.dispatch(new Navigate(['/purchase/salesman']));
    }

    public routeToNvidia() {
        this.store.dispatch(new Navigate(['/nvidia']));
    }

    gotoTerms() {
        this.router.navigateByUrl('/legal?section=terms');
    }

    openRainGoIfSignedIn() {
        const isAuthed = this.store.selectSnapshot(CoreState.isAuthenticated);

        if (!isAuthed) {
            const url = "https://www.raingo.co.za"; // add environment urls
            window.location.href = "https://www.raingo.co.za"
            return
        }

        this.dataLayerService.rainGoClicked();
        return this.sso.createUrl().then(
            (encodedUrl) => {
                window.open(encodedUrl, '_self')
            }
        );
    }

    private upgradeSaleModal(name, lastName, email) {
        const options = {
            size: 'md' as any,
            windowClass: 'slideInUp',
            centered: true,
            backdrop: 'static' as const,
            keyboard: false,
        };
        const salesModalRef = this.modalService.open(SalesUpgradeModalComponent, options);
        salesModalRef.componentInstance.subject = 'Monarch upgrade to rainOne 5G';
        salesModalRef.componentInstance.name = name;
        salesModalRef.componentInstance.lastName = lastName;
        salesModalRef.componentInstance.email = email;
        salesModalRef.componentInstance.gmData = `Monarch upgrade to rainOne 5G - submit form`;
        salesModalRef.componentInstance.fromPage = 'homepage';
    }

    removeQueryParams(): void {
        const navigationExtras: NavigationExtras = {
            replaceUrl: true,
            queryParams: {}
        };

        setTimeout(() => {
            this.router.navigate([], navigationExtras);
        }, 0);
    }

}
